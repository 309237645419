import Resource from '@/api/resource';
import request from '@/utils/request';

class ReportResource extends Resource {
  constructor() {
    super('liquidation');
  }

  //date_start, date_end
  liquidation(data) {
    return request({
      url: '/liquidation/export',
      method: 'get',
      params: data,
      responseType: 'blob',
    });
  }

  stock() {
    return request({
      url: '/stockmovement/export',
      method: 'get',
      responseType: 'blob',
    });
  }
  
  licences() {
    return request({
      url: '/licenselink/export',
      method: 'get',
      responseType: 'blob',
    });
  }

  //date_start,date_end,user_id,liquidation_id
  cart(data) {
    return request({
      url: '/cart/export',
      method: 'get',
      params: data,
      responseType: 'blob',
    });
  }

}

export { ReportResource as default };
