<template>
  <CCol col="12">
    <CCard>
      <CCardHeader> Movimientos </CCardHeader>

      <CCardBody>
        <div class="text-center">
          <CSpinner v-if="loading" variant="grow" size="lg" />
        </div>

        <CDataTable
          v-if="!loading"
          hover
          striped
          sorter
          :items="items"
          :fields="fields"
        >
          <template #ItemCode="data">
            <td>
              {{ data.item.stock_from.ItemCode }}
            </td>
          </template>
          <template #ItemName="data">
            <td>
              {{ data.item.stock_from.ItemName }}
            </td>
          </template>
          <template #from="data">
            <td>
              {{ data.item.stock_from.user.name }}
            </td>
          </template>
          <template #to="data">
            <td>
              {{
                data.item.stock_to
                  ? data.item.stock_to.user.name
                  : data.item.email
              }}
            </td>
          </template>
          <template #created_at="data">
            <td>
              {{ data.item.created_at | dateFormat }}
            </td>
          </template>
           <template #action="data">
            <td>
              <CButton
                class="float-right"
                color="success"
                @click="resend(data.item.id)"
                v-if="!data.item.stock_to"
              >
                <font-awesome-icon icon="share" />
              </CButton>
            </td>
          </template>
        </CDataTable>

        <CPagination
          v-if="!loading"
          :activePage.sync="page"
          :pages="lastPage"
          size="sm"
          align="center"
          @update:activePage="pageChange"
        />
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CCol>
</template>

<script>
import Resource from "@/api/resource";
import StockResource from "@/api/stock";
import moment from "moment";

const resource = new Resource("stockmovement");
const stockResource = new StockResource();

export default {
  name: "Movements",
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        {
          key: "ItemCode",
          label: "Código",
          sorter: true,
          filter: true,
        },
        {
          key: "ItemName",
          label: "Item",
          sorter: true,
          filter: true,
        },
        { key: "qty", label: "Cantidad", sorter: true, filter: true },
        { key: "from", label: "Desde", sorter: true, filter: true },
        { key: "to", label: "Hasta", sorter: true, filter: true },
        { key: "created_at", label: "Fecha", sorter: true, filter: true },
        { key: "action", label: "", sorter: false, filter: false },
      ],
      page: 1,
      perPage: 10,
      lastPage: 1,
      toasts: [],
    };
  },

  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },

  mounted() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },

  methods: {
    async resend(id){
      await stockResource.resendMask(id).then((response) => {
        this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Licencia reenviada con éxito",
          });
      }).catch((error) => {
        console.log(error);
      });
    },
    async getItems() {
      this.loading = true;
      try {
        const response = await resource.list({
          page: this.page,
          perPage: this.perPage,
        });
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.page = val;
    },
  },
};
</script>
