<template>
  <CRow>
    <CCol col="12" class="mb-2">
      <CButton
        class="float-right ml-2"
        color="info"
        @click="() => $router.push('/stock/0')"
        v-if="!issubdistributor"
      >
        Nuevo movimiento
      </CButton>
      <CButton
        class="float-right"
        color="success"
        @click="() => $router.push('/stock/client')"
        v-if="!isvendor"
      >
        Enviar licencia
      </CButton>
    </CCol>

    <CCol col="12" class="mb-2" v-if="isadmin">
      <form @submit.prevent="submit">
        <input type="file" @change="handleFileUpload( $event )">
        <CButton
          @click="send()"
          color="primary"
        >
          <CSpinner v-if="loading" variant="grow" />
          Cargar
        </CButton>
      </form>
      <a :href="this.backend+'/invoices/format.xlsx'">
        Descargar formato
      </a>
    </CCol>
    

    <CCol col="12">
      <CCard>
        <CCardHeader> Inventario </CCardHeader>

        <CCardBody>
          <div class="text-center">
            <CSpinner v-if="loading" variant="grow" size="lg" />
          </div>

          <CDataTable
            v-if="!loading"
            hover
            striped
            sorter
            :items="items"
            :fields="fields"
          />

          <CPagination
            v-if="!loading"
            :activePage.sync="page"
            :pages="lastPage"
            size="sm"
            align="center"
            @update:activePage="pageChange"
          />
        </CCardBody>
      </CCard>
    </CCol>
    <Movements />
    <CButton class="float-right mr-2" color="success" @click="exportData()">
      Exportar
    </CButton>
    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import Movements from "./Movements.vue";
import StockResource from "@/api/stock";
import ReportResource from "@/api/report";

const resource = new Resource("stock");
const resourceStock = new StockResource();
const reportResource = new ReportResource();

export default {
  name: "Stock",
  components: {
    Movements,
  },
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        {
          key: "ItemCode",
          label: "Código",
          sorter: true,
          filter: true,
        },
        {
          key: "ItemName",
          label: "Nombre",
          sorter: true,
          filter: true,
        },
        { key: "qtyonhand", label: "Cantidad", sorter: true, filter: true },
      ],
      page: 1,
      perPage: 10,
      lastPage: 1,
      file: null,
      toasts: [],
      isadmin: false,
      isvendor: false,
      issubdistributor: false,
      backend: "",
    };
  },
  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  mounted() {
    this.backend = process.env.VUE_APP_BACKEND_URL;
    this.isadmin = this.$store.state.user.role == "Administrador";
    this.isvendor = this.$store.state.user.role == "Vendedor BSmart";
    this.issubdistributor = this.$store.state.user.role == "Subdistribuidor";
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },

  methods: {
    handleFileUpload( event ){
      this.file = event.target.files[0];
    },
    async getItems() {
      this.loading = true;
      try {
        const response = await resource.list({
          page: this.page,
          perPage: this.perPage,
        });
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.page = val;
    },
    async send() {
      this.loading = true;
      let formData = new FormData();
      formData.append('file', this.file);
      await axios.post(this.backend+'/api/stockimport',
        formData,
        {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.user.token,
            'Content-Type': 'multipart/form-data',
            'Accept':'application/json',
          },
        }
      ).then((data) => {
        this.loading = false;
        this.getItems();
        this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Activado con exito",
          });
      })
      .catch((error) => {
        this.loading = false;
        var errors = error.response.data.message;
        console.log(error.response.data.message);
        this.toasts.push({
            title: "Error",
            color: "danger",
            message: errors,
          });
      });
    },
    async exportData() {
      await reportResource.stock().then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "movimientos.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
  },
};
</script>
