import Resource from '@/api/resource';
import request from '@/utils/request';

class StockResource extends Resource {
  constructor() {
    super('stock');
  }

  loadData(data) {
    return request({
      url: '/stockimport',
      method: 'post',
      params: data,
    },{
      headers: {
          'content-type': 'multipart/form-data',
      }
    });
  }

  sendMask(data) {
    return request({
      url: '/sendClient',
      method: 'post',
      params: data,
    });
  }

  resendMask(id) {
    return request({
      url: '/resendClient/'+id,
      method: 'get',
    });
  }

}

export { StockResource as default };
